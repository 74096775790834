@import "../variables";



html, body{
  overflow-x: hidden;
}

body {
  background-color: $body-background-color;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  font-family: $fontMain;
  //color: $baseFontColor;
  color: #311f29;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
#wrapper{
  background-color: $colorGrey;
}

