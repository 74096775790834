footer {
  position: relative;
  //border: 1px solid yellow;
  background: $colorBlack;
  //padding: 3rem;
  margin-top: 60px;
  &:before{
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    height: 50px;
    //border: 1px solid magenta;
    background: url(/res/images/site/footer-bg.svg) left top repeat-x;
  }



  .col-narrow {
    //border: 1px solid magenta;
    text-align: center;
  }
  .col-wide{
    //border: 1px solid $colorAccent;
  }
  .icon.romania {
    font-size: 250px;
    color: $colorHeading;
  }
}


.footer{
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  &__contact{
    flex: 1 100%;
    padding: 20px;
    font-size: 25px;
    //text-align: center;
    color: $colorAccent;
    a, a:visited{
      color: $colorAccent;
    }
    address{
      //text-align: left;
      font-style: normal;
      color: white;
      font-size: 18px;
    }
  }
  &__nav, &__links{
    flex: 0 0 50%;
    a{
      color: white;
    }
    border-bottom: 1px solid rgba($colorHeading,0.1);
    padding-bottom: 15px;
    margin-bottom: 15px;
    li{
      padding: 5px 0;
    }
  }
}

@include for-tablet-portrait-up(){
  footer{
    padding: 0;
    margin-top: 100px;
    > .row:first-child{
      //border-top: 1px solid transparent;
      padding-top: 40px;
    }
  }
  .footer{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    &__contact{
      padding: 0;
      //display: none;
      font-size: 16px;
      flex: 0 0 33%;

    }
    &__nav, &__links{
      flex: 0 0 33%;
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

@include for-tablet-landscape-up(){
  footer{
    .icon.romania {
      font-size: 300px;
    }
  }
  .footer{
    &__contact{
      position: relative;
      left: -8%;
      font-size: 23px;
    }
  }
}
@include for-desktop-up(){
  .footer{
    &__contact{
      font-size: 30px;
    }
  }
}