@import '1-tools/colours';



// ---------- FONTS ----------
$fontMain: 'Source Sans Pro', sans-serif;
$fontSecondary: 'Source Sans Pro', sans-serif;
$baseFontSize: 1.5rem;
$baseLineHeight: 1.4;
$baseFontColor: $color-dark;





// ---------- GENERAL SETTINGS ----------
$body-background-color: $colorGrey-light;




// ---------- LINKS ----------
$link-color: $colorBrand;
$link-color-hover: darken($link-color, 20%);

$link-color-brand: black;
$link-color-brand-hover: lighten($link-color-brand, 20%);





// ---------- MEDIA QUERIES BREAKPOINTS ----------

$for-phone-only: 479px;
$for-phone-big-and-up: 480px;
$for-tablet-portrait-and-up: 760px;
$for-tablet-landscape-and-up: 940px;
$for-desktop-and-up: 1366px;
$for-desktop-big-and-up: 1800px;



// ---------- CUSTOM ----------

$pre-color: darken($silver, 5%);
$button-color: #555;
$button-background-color: transparent;
$button-border-color: #bbb;

$button-color-hover: #333;
$button-border-color-hover: #888;



$selection-color: $colorAccent;
$img-selection-background-color: transparent;

$heading-color: #222;

$line-color: #ddd;

$fork-tag-color: white;
$fork-tag-background-color: blue;
$fork-tag-color-hover: white;
$section-background-color: rgba(255,255,255,0.7);
$section-shadow-color: rgba(0,0,0,0.1);

