@import '../1-tools/mixins';

.header{
  position: relative;
  //border-top: 1px solid $colorBrand;
  padding-top: 35px;
  background: url(/res/images/site/topheader-vector%20-%20last.svg) repeat-x center top;
  ////background-size: cover;



  &__wrapper{
    position: relative;
    min-height: 50px;
    width: 100%;
    //top: 30px;
    //border: 1px solid black;
    padding-top: 10px;
  }
}

@include for-tablet-portrait-up(){
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__wrapper{
      width: auto;
    }
  }
}