table{
  width: 100%;
}
th{
  text-align: left;
  background-color: $colorBrand;
  //font-size: 25px;
}
th:first-child, td:first-child{
  width: 100px;
}

th{
  padding: 10px 5px;
}
tr:nth-child(odd){
  background-color: lighten($colorBrand,25);
}
tr:nth-child(even){
  background-color: lighten($colorBrand,23);
}
td{
  padding: 0 4px;
  vertical-align: top;
  ul{
    padding-left: 30px;
    list-style: disc;
  }
}