@charset "UTF-8";

a img {
  border:none;
}
a {
  color:#1a1a1a;
  text-decoration:none;
}
//a:hover {
//  color:#000;
//}

hr, hr.full {
  display:block;
  margin:0 auto;
  width:48%;
  border:none;
  border-top:1px solid #d4d4d4;
  margin-top:1rem;
}
hr.full, hr.normal {
  width:100%;
}

/** helpers /**/

.absolute-center, .abs-center {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.hidden {
  display:none !important;
}
.visible {
  display:inherit !important;
}


.table {
  display:table !important;
  width:100%;
  height:100%;
}
.cell {
  display:table-cell !important;
  width:100%;
  height:100%;
}
.cell .content {
  text-shadow:1px 1px rgba(0,0,0,0.5);
}
.table.center-center .cell {
  vertical-align:middle !important;
}
.table.center-center .content {
  margin:0 auto !important;
  text-align:center !important;
}
.table.bottom-left .cell {
  vertical-align:bottom !important;
}
.table.bottom-left .content {
  text-align:left !important;
}

.extra {
  background-color:#fff;
  display:table;
  width:100%;
  margin-bottom:2rem;

  background-image:url(/res/images/site/bg.png);
  background-color:#f0f0f0;
}
.extra .left {
  display:table-cell;
  text-align:left;
  padding:0.8rem;
}
.extra .right {
  display:table-cell;
  text-align:right;
  padding:0.8rem;
}
.extra .subcontent {
  font-size:0.6em;
  color:#828282;
}
.extra .subcontent a {
  color:#828282;
}

.side {
  margin:2rem auto;
  display:inline-block;
  color: #250216;
  width:100%;
  //padding:
  h1, h2, p{
    //max-width: 50%;
  }
}
.side img, .side video {
  width:45% !important;
}
.side img, .side video, .side.left img, .side.left video {
  float:left;
  display:table-cell;
  margin-right:5%;
  margin-left:0;
}
.side.right img, .side.right video {
  float:right;
  display:table-cell;
  margin-left:5%;
  margin-right:0;
}
.side .content {
  display:table-cell;
  width:50%;
}
.side .title, .side .subtitle {
  color:#000;
}
.side .subtitle {
  margin-bottom:0.8rem;
  font-weight: bold;
}

br.clear {
  display:block;
  clear:both;
}

.cover {
  width:100%;
  height:600px;
  overflow:hidden;
  display:block;

  background:center no-repeat #000;
  background-size:cover;

  position:relative;
}
.cover video {
  min-height:600px;
  min-width:100%;
}
.cover .content {
  padding:0.8rem;
}

.cover h1, .cover .title, .cover .subtitle {
  color:#fff;
}
.cover .subtitle {
  opacity:0.85;
  -moz-opacity:0.85;
  -webkit-opacity:0.85;
}

.info {
  margin:1.2rem auto;
  //width:80%;
  /*font-size:0.6rem;*/
  //color: ;
  padding: 2rem 0;
}
.info img {
  width:100%;
  height:auto !important;
}

#info{
  padding: 0 2rem ;
}
ul.normal {
  list-style:circle !important;
  margin:auto !important;
}

/** gallery /**/
.gallery {
  margin:0px auto;
  width:100%;
  text-align:center;
}
.gallery img {
  vertical-align:central;
}
.gallery img, .gallery.w100 img {
  max-width:100px;
}
.gallery.small img, .gallery.w60 img {
  max-width:60px;
}
.gallery.large img, .gallery.w200 img {
  max-width:200px;
}
.gallery.huge img, .gallery.w300 img {
  max-width:300px;
}
.gallery.w600 img {
  max-width:600px;
}
.gallery.space img {
  margin-top:0.3rem;
  margin-bottom:0.3rem;
  margin-left:0.5rem;
  margin-right:0.5rem;
}

/** logo /**/
//.logo span {
//  display:none;
//}



/** page /**/

#page .content h1 {
  line-height:normal;
}


/** searchContainer /**/

#searchContainer {
  display:none;
  text-align:center;
}
#searchContainer h3 {
  color:#0f68c3;
  font-size:2rem;
  display:none;
}
#searchContainer input[type=text] {
  border:none;
  border-radius:0;
  line-height:1;
  background:none;
  -moz-appearance:none;
  -webkit-appearance:none;
  -o-appearance:none;
  -ms-appearance:none;
  width:280px;
  height:64px;
  padding:5px;
  font-size:0.875rem;
  font-style:normal;
  color:#3f4956;
  border:2px solid #0f68c3;
  border-right:none;

  font-size:3.2rem;
  font-weight:100;
  color:#0f68c3;
}
#searchContainer label {
  display:inline-block;
  width:60px;
  height:74px;
  border:2px solid #0f68c3;
  border-left:none;
  vertical-align:top;
  background-image:url(/res/images/site/design.png);
  background-repeat:no-repeat;
  background-position:-710px 0px;
  cursor:pointer;
}
#searchClose {
  width:100%;
  text-align:right;
  line-height:0;
  margin-bottom:10px;
}
#searchCloseBtn:before {
  content:"(X)";
  margin-right:5px;
}
#searchCloseBtn {
  border:none;
  border-radius:0;
  line-height:1;
  background:none;
  -moz-appearance:none;
  -webkit-appearance:none;
  -o-appearance:none;
  -ms-appearance:none;
  cursor:pointer;
  padding:5px;
}


/* errors */
#errors {
  display:block;
  padding:0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  padding:0.75rem;
  margin-bottom:20px;
  text-align:left;
  box-shadow:0.5px 0.866px 16px 0px rgba( 0, 0, 0, 0.8 );
}
.error {
  border:2px dashed #ffe4e4;
  border:2px dashed #FC7C78;
  background-color:#ffe4e4;
}
.warning {
  border:2px dashed #eeeaca;
  border:2px dashed #DDEA5E;
  background-color:#eeeaca;
}
.notice {
  border:2px dashed #e0f0d5;
  border:2px dashed #8CEE61;
  background-color:#e0f0d5;
}

/* splitPages */
.splitPages {
  text-align:center;
  font-size:1.25rem;
}
.splitPages a, .splitPages span {
  padding:0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  margin:0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  display:inline-block;
  font-weight:400;
  text-decoration:none;
}
.splitPages a {
  color:#0f68c3;

  border-width:1px;
  border-color:#fff;
  border-style:solid;
  border-radius:50%;
  background-color:#fff;
  background-color:rgb(255, 255, 255);
  width:26px;
}
.splitPages a:visited {

}
.splitPages .current {
  font-weight:600;

  color:#0f68c3;
  color:#fff;

  border-width:1px;
  border-color:#fff;
  border-style:solid;
  border-radius:50%;
  background-color:#c02735;
  width:26px;

  cursor:default;
}
.splitPages .next, .splitPages .last, .splitPages .back, .splitPages .first {
  font-size:0.85rem;
}
.splitPages:before, .splitPages:after {
  font-size:0.75rem;
  font-weight:100;
}

.ro .splitPages:before {
  content:"Mai multe produse:";
}
.en .splitPages:before {
  content:"More products:";
}

/** media **/

@media only screen and (max-height: 768px) {

  .cover {
    height:400px;
  }

}

@media only screen and (max-width: 1024px) {

  #subpages li {
    width:50% !important;
  }
  #subpages.count3 li {
    width:100% !important;
  }

  .slogan, .copyright span {
    display:block;
  }

}

@media only screen and (max-width: 768px) {
  .info{
    padding: 0;
  }

  .side img, .side video {
    width:100% !important;
    padding:0 0 1rem 0 !important;
    margin:0 auto;
  }


  .gallery img, .gallery.w100 img {
    max-width:60px;
  }
  .gallery.small img, .gallery.w60 img {
    max-width:40px;
  }
  .gallery.large img, .gallery.w200 img {
    max-width:100px;
  }
  .gallery.huge img, .gallery.w300 img {
    max-width:150px;
  }
  .gallery.w600 img {
    max-width:320px;
  }
  .gallery.space img {
    margin-top:0.2rem;
    margin-bottom:0.2rem;
    margin-left:0.35rem;
    margin-right:0.35rem;
  }

}

@media only screen and (max-width: 500px) {

  .cover {
    height: 240px;
  }

}
@media screen and (min-width: 1460px) {

  #info{
    padding: 0;

  }

}