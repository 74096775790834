.price_tag{
  font-size: 40px;
  font-weight: bold;
  .card--small &{
    font-size: 20px;
  }
}

.jumbotron{
  position: relative;
  //background: url("/res/images/site/homepage.jpg") no-repeat center;

  background-size: cover;
  min-height: 300px;
  border-top: 3px solid $colorBrand;
  display: flex;
  align-items: stretch;
  margin-bottom: 40px;


  //display: flex;
  &:after{
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //background-color: rgba(0,0,0,0.2);
    background-size: cover;

  }


  &__inner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    //border: 1px solid black;
    z-index: 1;
  }
  &__title{
    align-self: center;
    display: inline-block;
    max-width: 600px;
    width: auto;
    margin: 20px;
    padding: 30px;
    font-size: 30px;
    font-weight: bold;
    color: $colorBlack;
    background-color: rgba(white,0.6);
    //background-color: white;

  }
  &__contact{
    display: none;
    flex: 0 0 350px;
    //background-color: rgba(black,0.4);
    //background-color: $colorAccent;
    font-size: 40px;
    color: white;
    padding: 20px;
    text-align: right;
    font-weight: bold;

    address{

      font-size: 25px;
      font-style: normal;
      margin-top: 20px;
      //color: $colorBlack;
    }
    hr{
      border-color: rgba(white,0.4);
    }
  }
}
@include for-tablet-portrait-up(){

  .jumbotron{
    min-height: 400px;
    &__contact{
      display: block;

    }
    &__title{
      font-size: 50px;
      //margin: 0;
    }
  }

}
@include for-desktop-up(){
  .jumbotron{
    &__title{
      //font-size: 50px;
      margin: 0;
    }
  }
}