@import "../variables";

//	Headings



.h1, .h2, .h3,
h1, h2, h3, h4, h5, h6 {
  font-family: $fontSecondary;
  font-weight: 300;
  color: $colorHeading;
  line-height: 1.15;
  //margin-top: 2rem;
  &:first-child{
    margin-top: 0;
  }
}

h1, h2, h3, .h1, .h2, .h3{
  font-size: 24px;
  .row--first &{
    margin-top: 0;
  }
  @include for-tablet-portrait-up(){
    font-size: 33px;
  }
}

// Style headings through classes

.h1{
  font-weight: normal;
  color: $colorBlack;
  line-height: 1.1;
}
.h1-big{
  font-size: 27px;
  @include for-tablet-portrait-up(){
    font-size: 45px;
  }
}
.h-large{
  @include clearfix();
  //text-align: right;
  font-size: 27px;
  max-width: 600px;
  //margin-left: auto;
  //margin-right: auto;
  margin-bottom: 40px;
  @include for-tablet-portrait-up(){
    font-size: 40px;
    margin-bottom: 30px;

    color: $colorBlack;
  }
}


// Custom Basic Text Style///

p {
  margin: 0 0 2rem 0;
}

em {
  font-style: italic;
}
strong {
  font-weight: bold;
}
hr {
  border: solid $line-color;
  border-width: 1px 0 0;
  clear: both;
  margin: 1rem 0 3rem;
  height: 0;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;

}

#description{
  ul{
    list-style: square;
    padding-left: 5rem;
    li{
      padding: 0.5rem 0;
    }
  }
}

