.nav-main{
  position: absolute;
  left: 0;
  height: 0;
  background-color: $colorGrey;
  width: 100%;
  transition: height .5s;
  overflow: hidden;
  box-shadow: 0 8px 6px -6px rgba(0,0,0,0.4);
  z-index: 100;


  #nav-input:checked ~ &{
    height: 75vh;
    overflow: scroll;

    a{
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    //style for nav-main__contact when menu open
    .nav-main__contact{

    }
  }

  ul{
    text-align: left;
    padding: 0 30px;
  }
  li{
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) a {
        transition: all (0.2s + 0.1s * $i);
      }
    }
    a{
      display:block;
      opacity: 0.5;
      transform: translate3d(0, -100px, 0);
      padding:10px 0;
      border-bottom: 1px solid rgba($colorBrand, .25);
      color: $colorBlack-light;
      font-size: 18px;
      font-family: $fontSecondary;
      font-weight: 300;
      text-align: left;

      &.active{
        color:black;
      }
    }
  }
  &__contact {
    p {
      color: $colorBlack-light;
      font-size: 18px;
      font-family: $fontSecondary;
      font-weight: 300;
      padding: 10px 30px;
      margin: 0;
    }
    .icon, span {
      vertical-align: middle;
    }
    .icon {
      margin-right: 10px;
    }
  }

}



@include for-tablet-portrait-up(){
  .nav-main, #nav-input:checked ~ .nav-main{
    position: relative;
    height: auto;
    overflow: visible;
    background-color: transparent;
    text-align: right;
    box-shadow: none;
    ul{
      display: inline-block;
      padding: 0;
      margin-right: -15px;
    }
    li{
      display: inline-block;
      padding: 0 10px;


      a{
        transform: translate3d(0, 0, 0);
        opacity: 1;
        padding: 5px;
        font-size: 16px;
        border: none;
        font-weight: 400;
        border-bottom: 3px solid $colorGrey;
        &:hover{
          color: $colorBrand;
          border-bottom-color: $colorBrand;
        }
        &.menuitem-active{
          border-bottom-color: $colorBrand;
        }
      }
    }
  }
}

@include  for-tablet-portrait-up(){
  .nav-main{
    &__contact{
      position: absolute;
      top: 90px;
      right: 0;
      //border: 1px solid red;

      a, p {
        font-size: 33px;
        //font-weight: bold;
        padding: 0;
        line-height: 1;
        color: $colorBlack-light;
        font-weight: normal;
      }
      p{
        margin-bottom: 0;
      }
      .icon{
        display: none;
      }
    }
  }
}
@include for-tablet-landscape-up(){
  .nav-main{
    &__contact{
      top: 95px;
    }
  }
}
@include  for-desktop-up(){
  .nav-main{
    ul{
      padding-right: 0;
    }

  }
}





//
//@media screen and (min-width: 680px){
//
//  .nav-main, .nav-main.is-open{
//
//    background-color: transparent;
//
//    ul{
//
//    }
//    li {
//      display: inline-block;
//      a{
//        display: inline-block;
//        border: none !important;
//        font-size: 16px;
//
//        &:hover{
//          color: $colorBrand;
//          cursor: pointer;
//        }
//      }
//    }
//  }
//}
//
//
//
//@media screen and (min-width: 875px){
//  .toggleNav{
//    display: none;
//  }
//
//  .nav-main, .nav-main.is-open{
//    order:1;
//    //height: 55px;
//    //line-height: 55px;
//    background-color: transparent;
//    text-align: left;
//    display: block;
//
//
//    li {
//      display: inline-block;
//
//    }
//
//    li a{
//      display: inline-block;
//      border: none !important;
//      //font-size: 16px;
//    }
//  }
//}
//
//@media screen and (min-width: 1024px){
//  .nav-main, .nav-main.is-open{
//    position: absolute;
//    bottom: 0;
//    right: 0;
//    ul{
//      text-align: right;
//    }
//    li {
//      a{
//        //font-size: 18px;
//      }
//    }
//  }
//
//}