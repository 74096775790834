

// Hidden input to track toggle state - always hidden
#nav-input{
  display: none;
}


// Transform bars to make an X when input is checked(burger active)
#nav-input:checked + .header__wrapper .nav-toggle label{
  .nav-toggle__bar{
    &.top{
      opacity: 0;
      transform: translate3d(0, -15px, 0);
    }
    &.middle-top{
      transform: rotateZ(45deg);
    }
    &.middle-bottom{
      transform: rotateZ(-45deg);
    }
    &.bottom{
      opacity: 0;
      transform: translate3d(0, 15px, 0);
    }
  }
}

// -------------------------------- Nav Toggle -------------------------------- //

.nav-toggle{
  position: absolute;
  @include center(y);
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  //border: 1px solid black;
  overflow: hidden;

  //Expand bars when hover
  //&:hover{
  //  .nav-toggle__bar{
  //    &.top{
  //      transform: translate3d(0, -3px, 0);
  //    }
  //    &.bottom{
  //      transform: translate3d(0, 3px, 0);
  //    }
  //  }
  //}

  label{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    cursor: pointer;
  }
  &__bar{
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 14px;
    height: 2px;
    margin: -1px 0 0 -7px;
    background: $colorBlack-light;
    transition: all 150ms;
    &.top{
      margin-top: -5px;
    }
    &.bottom{
      margin-top: 3px;
    }
  }
  @include for-tablet-portrait-up(){
    display: none;
  }
}