.form-control{
  padding: 10px;
  width: 100%;
  margin: 5px 0;

}
textarea.form-control{
  min-height: 200px;
}


#form-messages{
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s;
  background-color: $colorAccent;
  color: white;
  font-size: 20px;
  &.visible{
    transform: scale(1);
    opacity: 1;
    border: 1px solid $colorAccent;
  }
}