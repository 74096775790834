
.component_two{
  //border: 1px solid magenta;
  padding: 30px 0;

  &__item{
    text-align: center;
    margin-bottom: 20px;
  }
  &__title{
    text-transform: uppercase;
    font-size: 20px;
  }
}

@include for-tablet-portrait-up(){
  .component_two{
    padding: 80px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    &__item{
      flex: 0 0 30%;
    }
  }
}
@include for-tablet-landscape-up(){
  .component_two{
    &__item{
      flex: 0 0 15%;
      margin-bottom: 0;
    }
  }
}