




.screen-reader-text {
  clip: rect(1px,1px,1px,1px);
  position: absolute!important;
  height: 1px!important;
  width: 1px!important;
  overflow: hidden;
}






.is-active{
  display:block;
}

.is-active-flex{
  display:flex;
}

.is-visible{
  visibility: visible;
}
.is-invisible{
  visibility: hidden;
}

.pull-right{
  float: right;
}

//

.image{
  display: block;
  height: 0;
  padding-top: 60%;
  margin: 3% 12%;
  background: no-repeat center;
  background-size: contain;
}

.bg-grey{
  background-color: $silverlight;
}
.bg-pattern{
  padding: 1.5rem;
  background: $colorBrand url(/res/images/site/pattern-transparent.png);
  color: white;
}

.text-center{
  text-align: center;
}

.text-big{
  font-size: 19px;
}.text-bigger{
  font-size: 24px;
   font-weight: 300;
}
 .text-large{
   font-size: 25px;
   font-weight: 300;
 }

.text-brand{
  color: $colorBrand;
}
.text-accent{
  color: $colorAccent;
}

.screen-reader-text{
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}


.submit-wrapper{
  position: relative;
  display: inline-block;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.loader {
  position: relative;
  top: 0;
  //left: 100%;
  display: none;
  vertical-align: middle;
  margin: 0 auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 0.6em solid rgba(255, 255, 255, 0.2);
  border-right: 0.6em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.6em solid rgba(255, 255, 255, 0.2);
  border-left: 0.6em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  &.in-progress{
    display: inline-block;
  }

}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}





@media screen and (min-width: 680px) {
  .text-large{
    font-size: 33px;
  }
}

@media screen and (min-width: 768px) {
  .bg-pattern{
    padding: 4rem;
  }
}