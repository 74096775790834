
#wrapper {
  //max-width: 1366px;
  margin: 0 auto;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

}

.row{
  display: block;
}
.row--cards{
  .col-narrow{
    padding: 0;
  }
}
.col-narrow{
  flex: 0 0 40%;
  //border: 1px solid $colorBrand;
  padding: 10px;
  &--white{
    background-color: white;
  }
  .row--first &{
    flex: 0 0 55%;
  }
}
.col-wide{
  flex: 0 0 60%;
  //padding: 10px;
  //border: 1px solid $colorAccent;
  padding: 30px 0;
}
.col-full{
  flex: 1;
}




@include for-tablet-portrait-up() {
  .row{
    display: flex;
    .col-wide{
      padding: 0 0 0 30px;
    }
    &--cards{
      display: block;
      .col-wide{
        padding-left: 0;
      }
    }
    &--first{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &--large{
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  .container {
    .header & {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .header__wrapper {
        width: auto;
      }
    }
  }
}
@include for-tablet-landscape-up(){
  .row{
    &--cards{
      display: flex;
    }

  }
}

@include for-desktop-up() {

  .container {
    margin: 0 auto;
    max-width: 1200px;

  }

}