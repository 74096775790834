

.component_one{
  position: relative;
  background: $colorAccent no-repeat;
  color: $colorBlack;
  padding: 30px 0;
  margin-top: 70px;
  margin-bottom: 70px;
  &:before{
    //display: none;
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 50%;
    background: url(/res/images/site/svg/middle-top-full.svg) repeat-x top left;
    //background-size: 100%;
    //border: 1px solid magenta;
    z-index: 1;
  }
  &:after{
    //display: none;
    content: '';
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    //left: -14px;
    //right: -14px;
    background: url(/res/images/site/svg/footer-good.svg) repeat-x;
    //background-size: 100%;
    height: 90px;
    //border: 1px solid magenta;
  }
  &__title{
    position: absolute;
    top: -30px;
    z-index: 1;
  }
  &__subtitle{
    font-weight: 400;
    color: $colorBlack;
  }
  .col-wide{
    padding: 30px 0;
  }
  .col-narrow img{
    min-width: 260px;
  }
}
@include for-tablet-portrait-up(){
  .component_one{
    .row{
      .col-wide{
        padding: 80px 0;
      }
      .col-narrow img{
        min-width: 280px;
      }
    }
  }
}
@include for-tablet-portrait-up(){
  .component_one{
    .col-narrow img{
      min-width: 360px;
    }
  }
}