.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(/res/icons/symbol-defs.svg);
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  font-size: 25px;

  //hack to prevent CKeditor to deleting icons
  hr{
    display: none;
  }
}



.icon-logo{
  font-size: 50px;
  width: 3.0615234375em;
  @include for-tablet-portrait-up(){
    font-size: 50px;
  }
  @include for-tablet-landscape-up(){
    font-size: 80px;
  }
  color: $colorBlack-light;
}
.logo-shape-1{
  color: $colorBrand;
  stroke: currentColor;
  fill: currentColor;
}
.logo-shape-2{
  color: $colorAccent2;
  stroke: currentColor;
  fill: currentColor;
}