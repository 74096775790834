
.card-deck{
  @include clearfix();
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 30px;
}
.card-inner{
  background-color: white;
  border-top: 3px solid $colorBlack-light;

  display: block;
  height: 100%;
  width: 100%;
  color: $colorBlack;
  &:hover, &:active{
    color: lighten($colorBlack,10);
  }
  .card--large &{
    border-top-color: $colorAccent;
    display: flex;
    align-items: center;
  }
  .card--small &{
    border-top-color: $colorSecondary;
  }
}
.card{
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  figure{
    margin: 0 20px 0 0;
  }
  p{

    &:last-child{
      margin-bottom: 0;
    }
  }
  &--large{
    width: 100%;
    figure{
      margin: 20px;
      text-align: center;
    }
    .icon{
      font-size: 90px;
      color: $colorAccent;
    }
    .card__title{
      font-weight: normal;
      font-size: 24px;
      text-align: left;
    }
  }
  &--small{
    //height: 155px;
    //float: left;
    width: 50%;
    .card__title{
      font-weight: bold;
    }
    figure{
      margin: 10px 10px 0 10px;
    }
    .icon{
      font-size: 40px;
      color: $colorSecondary;
    }
    p{
      line-height: 1.1;
    }
  }


  &__title{
    font-size: $baseFontSize;
    margin-top: 0;
    margin-bottom: 0.2em;
    color: $colorBlack;
  }
  &__content{
    margin: 0 10px 10px 10px;
  }
}


@include for-tablet-portrait-up(){
  .card-deck{
    display: block;
    &.compact{
      display: flex;
      .card--large{
        float: none;
        height: auto;
        .card-inner{
          display: flex;
        }
        .card__title{
          text-align: left;
        }
        .card__content{
          padding: 20px 0;
        }
        .icon{
          font-size: 75px;
        }
      }
      .card--small{
        float: none;
        height: auto;
        figure{
          margin: 20px;
        }
      }
    }
  }
  .card{
    &--large{
      width: 40%;
      float: left;
      height: 300px;
      overflow: hidden;
      figure{
        margin: 20px;
      }
      .icon{
        font-size: 100px;
      }
      .card-inner{
        display: block;
      }
      .card__title{
        font-size: 28px;
        text-align: center;
      }
    }
    &--small{
      width: 20%;
      height: 145px;
      float: left;
      overflow: hidden;
      .icon{
        font-size: 40px;
      }
      .card__content{
        padding: 0 10px 10px 10px;
      }
    }
    &__content{
      padding: 20px;
      margin: 0;
    }
  }
}

@include for-tablet-landscape-up(){
  .card{
    &--large{
      //margin-right: 15px;
      //height: 250px;
    }
    &--small{
      //flex: 0 0 32.7%;
      //height: 120px;
    }
  }
}
@include for-custom-up(1150px){
  .card{
    text-align: left;
    .card-inner{
      display: flex;
      align-items: center;
      .card__content{
        flex: 1;
      }
    }
    &--large{
      height: 250px;
      .card__title{
        text-align: left;
        font-size: 33px;
      }
    }
    &--small{
      height: 120px;

      .card__content{
        padding: 10px 10px 10px 0;
      }

      //flex: 0 0 32.7%;
      //height: 120px;
    }
  }
}
@include for-desktop-up(){
  .card-deck{
    margin: 0 auto 30px;
    max-width: 1200px;
  }
}