

.logo{
  position: absolute;
  @include center();
  display: inline-block;
  width: 150px;

  @include for-tablet-portrait-up(){
    //width: 180px;
    position: relative;
    left: 0;
    top: 0;
    transform: translateX(0%) translateY(0%);
    h1{
      font-size: 35px;
    }
  }
  @include for-tablet-landscape-up(){
    width: 250px;
    h1{
      font-size: 47px;
    }
  }
}
